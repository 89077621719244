import { nava } from './style';

function HashLinkNavItem(props) {
  return <a href={`#${props.to}`} onClick={smoothScroll} style={{...nava, ...props.style}} className={window.location.pathname === props.to ? 'current' : ''}>{props.children ?? props.name}</a>;

  function smoothScroll(ev) {
    ev.preventDefault()
    const id = ev.currentTarget.getAttribute('href').slice(1)
    const $anchor = document.getElementById(id);
    const offsetTop = $anchor?.getBoundingClientRect().top + window.pageYOffset ?? 0;
    window.scroll({ top: offsetTop, behavior: 'smooth' })
    props.onClick?.();
  }

  //if need polyfill for smooth scroll see react router hash link doc
}

export default HashLinkNavItem;