import RsvpNavItem from '../components/nav/rsvpNavItem';
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { standardContrast } from '../color-scheme'
import { isMobileQuery, isTabletQuery } from "../screenSizes"
import logo from '../pics/prenoms_site_florine.png'

const Title = styled.h3`
color:${standardContrast.color};
font-size: 2em;
`

function Fold() {

  const isTabletSize = useMediaQuery(isTabletQuery)
  const isMobileSize = useMediaQuery(isMobileQuery)

  return <>
        <div style={{position:"absolute",top:"33%", left:"50%",transform:"translateX(-50%)", display:"flex",flexDirection:"column",justifyContent:"center"}}>
           <div style={{display:"flex",justifyContent:"center", textAlign:"center"}}>
              <img alt="Florine ❤ Jérôme" style={{width:"90vw", maxWidth:"900px"}} src={logo} />
           </div>
           {/* <div style={{display:"flex",justifyContent:"center", textAlign:"center"}}>
           //    <Title style={{width:isMobileSize?"4em":"auto"}}><em>Just ❤ Married!</em></Title>
           // </div> */}
       </div>
      {isTabletSize && <div style={{display:"flex",justifyContent:"center", width:"90vw", position:"absolute", bottom:"25%", left:"50%", transform:"translateX(-50%)"}}><RsvpNavItem/></div>}
  </>
}

//NOTE: sur moile, le deisgn pas top, mais comme je peux pas utiliser de vh, et que je vais pas me taper un media query par viewport sur le marché, j'ai fait un compromis
// rappel = avec les vh ça fait tout foirer quand le clavier apparait/disparait ou quand la barre aved l'url apparait disparait (quand on scroll) et ça fait "sauter" le scroll c'est ultra relou
export default Fold;