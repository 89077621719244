//we start from mobile, and work our way upward toward desktop

const limitMobileSize = "751px";
const isMobileQuery = {
  query: `(max-width: ${limitMobileSize})`
}

const limitTabletSize = "1169px";
const isTabletQuery = {
  query: `(max-width: ${limitTabletSize})`
}


export {
  isMobileQuery,
  isTabletQuery,
}
