import { textSection } from './../../components/section/articlecss'

const height = "400";
const vids = [
  "JN2J5D_KXPA",
  "P0EyPk0ilbM",
  "4Jr41acnTiQ",
  "GgmX-Pk3D1g"
]



function Videos() {
  return <>
  <h3 style={{marginTop:"8%"}}>Les videos de Tristan, notre vidéaste, sont visible directement sur place...</h3>
    <p style={{marginTop:20}}>...ou vous pouvez les télécharger en haute qualité <a href="https://www.grosfichiers.com/FJhaKEe3GCW" target="_blank">ici</a>.</p>
    {vids.map(Frame)}
</>
}

function Frame(src) {
  return <div style={{marginTop:"5%"}}>
  <iframe width="100%" height={height} src={`https://www.youtube-nocookie.com/embed/${src}`} title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
}



export default Videos;