import { textSection } from './../../components/section/articlecss'
import Photos from './photos';
import Videos from './videos';

function PhotosVideos() {

  return <article style={textSection}>
   <Photos/>
   <Videos/>
</article>
}

export default PhotosVideos;


//   {/* <!-- see rampatra photo section --/>*/}