import './burgerButton.css';
import { standardContrastDarker } from '../../../color-scheme';
import { useEffect } from 'react';

function BurgerButton(props) {
  useEffect(() => {
    props.toggled ? disableScroll() : enableScroll();
  }, [props.toggled])

  return (
    <>
      <div id="burgericon" style={props.style} className={props.toggled?"menudisplayed":""} onClick={()=>{props.setToggled(!props.toggled)}}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
      </div>
      {props.toggled && <div style={foggyMenu}>{props.children}</div>}
    </>
  )
}

const foggyMenu = {
  background: standardContrastDarker.background + 'EE',
  height: "100vh", //okay car no scroll when this is displayed
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 5
}

function noScroll() {
  window.scrollTo(0, 0)
}

const disableScroll = () => {
  window.addEventListener('scroll', noScroll);
}
const enableScroll = () => {
  window.removeEventListener('scroll', noScroll);
}

export default BurgerButton;