import { standardContrastDarker } from '../color-scheme'

import { Mail, Call } from 'react-ionicons'

import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from "../screenSizes"

import { Row, Row2 } from '../components/layout'

const style = {
  padding: "2vw 2em"
}

function Footer() {

  const isMobileSize = useMediaQuery(isMobileQuery)
  const flexDirection = isMobileSize ? "column" : "row"

  return <article style={{...standardContrastDarker,...style}}>
       <h2>Contact</h2>
       <div style={{display:"flex",justifyContent:"space-around",flexDirection}}>
         <section>
           <h3>Les amoureux</h3>
           <Row columns={[<Call />,"06 67 34 27 42"]} />
           <Row columns={[<Call />,"06 11 94 57 12"]} />
           <Row columns={[<span style={{paddingTop:"5px"}}><Mail /></span>,<a href="mailto:mariage@jeromeetflorine.fr">mariage@jeromeetflorine.fr</a>]}/>
         </section>
         <section>
            <h3>Les témoins</h3>
            <Row2 style={{color:standardContrastDarker.label}} columns={["Cédric","07 70 74 34 91"]} />
            <Row2 style={{color:standardContrastDarker.label}} columns={["Mélissa","06 31 64 63 93"]} />
            <Row2 style={{color:standardContrastDarker.label}} columns={["Sophie","06 69 51 13 94"]} />
            <Row2 style={{color:standardContrastDarker.label}} columns={["Emmanuella","06 25 11 78 88"]} />
            <Row2 style={{color:standardContrastDarker.label}} columns={["Jean","07 55 73 97 20"]} />
            <Row2 style={{color:standardContrastDarker.label}} columns={["Dorian","06 98 75 55 90"]} />
            <Row2 style={{color:standardContrastDarker.label}} columns={["Emeline","06 62 01 31 16"]} />
            <Row2 style={{color:standardContrastDarker.label}} columns={["Julien","07 36 65 65 65"]} />
         </section>
       </div>
    </article>
}

export default Footer;