const nava = {
  padding: '0.2rem',
  fontSize: '1.5rem',
  color:'inherit',
  textDecoration:'none',
  textAlign:'center'
}


export {
  nava
}
