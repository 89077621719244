import styled from 'styled-components'
import { Background } from '../background'

//according to mainContent
const negm = "0 0 0 -2vw";
//100+2*negm
const bgWidth = "102vw";

export const SectionTitle = styled.h2`
  text-align:center;
  margin:2em 0;
`;

const Background2 = Background(bgWidth,negm)

function Section(props) {

  return (
    <section style={{position:"relative", minHeight:"700px", background:props.backgroundColor, ...props.style  }} id={props.to}>
       <Background2 background={props.backgroundImg}>
         <div style={{height:props.topSpacer}}></div>
         {props.title && <SectionTitle>{props.title}</SectionTitle>}
         {props.children}
       </Background2>
    </section>
  );
}

export default Section;
