import styled from 'styled-components'

const Label = styled.label`
  margin-right:1em;
  font-weight:600;
`;

export const Aligner = styled.div`
  display: flex;
  align-items: center;
`;

export const Row = (props) => <Aligner style={{fontSize: "1.2em"}}><Label style={{...props.style}}>{props.columns[0]}</Label><p style={{fontFamily:"mono"}}>{props.columns[1]}</p></Aligner>
export const Row2 = (props) => <Aligner style={{fontSize: "1.2em", justifyContent:"space-between"}}><Label style={{...props.style}}>{props.columns[0]}</Label><p style={{fontFamily:"mono"}}>{props.columns[1]}</p></Aligner>