
export const primary = '#e3b0a3';
export const secondary = '#F0F0F0';

// Main background and text
export const standardContrast = {
  background:'#FFFFFF',
  color:'#000000',
}

export const standardContrastDarker = {
  label:'#000000',
  background: '#282c34',
  color:'#999'
}

//Buttons and boxes
export const secondaryContrast = {
  background:secondary,
  color:'#000000',
}

export const primaryContrast = {
  background:primary,
  color:'white',
}
