import styled from 'styled-components'
import { isMobileQuery } from "../screenSizes"
import { useMediaQuery } from 'react-responsive'

export const Background = (bgWidth,negm = 0, borderRadius = 0) => styled.section`
&:before {
  content: ' ';
display: block;
position: absolute;
left: 0;
top: 0;
height:100%;
width: ${bgWidth};
opacity: 0.4;
margin:${negm};
background-image: url(${props => props.background});
background-repeat: no-repeat;
background-position: 50% 0;
background-size: cover;
z-index:-1;
border-radius:${borderRadius};
 }
`
const Bo = Background("100%", 0, "30px");
export const Background2 = (props) => {

  const isMobileSize = useMediaQuery(isMobileQuery)

  return <article style={{position:"relative",width:isMobileSize?"100%":"700px"}}>
     <Bo {...props}>
       <div style={{padding:"1.5em"}}>
           {props.children}
       </div>
     </Bo>
  </article>
}
