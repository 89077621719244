import { useState, React } from 'react';

// data
import { mainSections } from '../../sections'

// components
import HashLinkNavItem from './hashLinkNavItem';
import RsvpNavItem from './rsvpNavItem';
import BurgerButton from './burgerButton/burgerButton';

//responsive
import { useMediaQuery } from 'react-responsive'
import { isTabletQuery } from "../../screenSizes"

// layout data
import { standardContrastDarker } from '../../color-scheme'
import './nav.css'

//
import initiales from '../../pics/initiales_512px.png'

const burgerButtonPosition = {
  position: "absolute",
  zIndex: 10,
  top: '2em',
  left: '2em'
}

function Nav(props) {

  const isTabletSize = useMediaQuery(isTabletQuery)

  if (isTabletSize) {
    return <BurgerNav/>
  }

  return <DesktopNav/>;
}

function BurgerNav() {

  const [toggled, setToggled] = useState(false);

  return (
    <BurgerButton toggled={toggled} setToggled={setToggled} style={burgerButtonPosition}>
          <BaseNav
              style={{height: "100vh", flexDirection: "column", marginTop: '5em', color: standardContrastDarker.color}}
              rsvpNavItemStyle={{position: 'absolute', bottom: '6em'}}
              onClick={()=>setToggled(false)}
          />
      </BurgerButton>
  )
}


function DesktopNav(props) {
  return <BaseNav style={{height:props.height}} rsvpNavItemStyle={{marginLeft: 'auto'}} /> //https://medium.com/@iamryanyu/how-to-align-last-flex-item-to-right-73512e4e5912
}

function BaseNav(props) {
  return (
    <nav style={props.style} >
      <HashLinkNavItem {...mainSections[0]} key={mainSections[0].to} onClick={props.onClick} style={{marginTop:"8px"}}>
        <img className="navLogo" src={initiales} alt="logo"/>
      </HashLinkNavItem>
      {mainSections.slice(2).map((navItem) => <HashLinkNavItem {...navItem} key={navItem.to} onClick={props.onClick}/>)}
      <RsvpNavItem style={props.rsvpNavItemStyle} onClick={props.onClick}/>
    </nav>
  );
}



export default Nav;