import { textSection } from './../../components/section/articlecss'

function Photos() {

  return <>
   <h3 style={{marginTop:"8%"}}>Les photos prises par Seb, notre photographe...</h3>
   <p>...sont visibles <a href="http://florine-jrome.sebastien-benduckieng.com/" target="_blank">dans la galerie</a> avec le mot de passe FLOJE</p>
   <h3 style={{marginTop:"8%"}}>Vos superbes grimaces au photobooth...</h3>
   <p>...ne sont pas passées inaperçues ;) Retrouvez les juste <a href="https://www.dropbox.com/sh/boptmkdyybcafvh/AACpzPQgomj-62PPAb1_BUn4a?dl=0">là</a> !</p>
   <h3 style={{marginTop:"8%"}}>Les photos prises par nos invités adorés</h3>
   <p>Vous avez quelques photos ou vidéos du jour j ? On aimerais les voir ! Vous pouvez les partager <a href="https://www.dropbox.com/request/oMQdDFV0ohBv995RxCIZ" target="_blank">par ici</a> !</p>
   <p>Si vous voulez juste regarder ce qui a déjà été partagé, c'est par <a href="https://www.dropbox.com/sh/9hklmzg13nz52tn/AADGFRuHcn0FUjQs7xuMHKX3a?dl=0" target="_blank"> là</a> !</p>
</>
}

export default Photos;


//   {/* <!-- see rampatra photo section --/>*/}