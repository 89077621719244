import './App.css'
import Section from './components/section/section'
import Nav from './components/nav/nav'
import Footer from './scenes/footer'
import { sections } from './sections'

import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from "./screenSizes"

const defaultNavHeight = '6em';

function App() {

  const isMobileSize = useMediaQuery(isMobileQuery)
  const navHeight = isMobileSize ? 6 : defaultNavHeight;
  const foldSection = sections[0];

  return (
    <>
      <div className="mainContent">
        <Nav height={navHeight} />
        <Section key={foldSection.to} topSpacer={0} style={{height:isMobileSize ? "" : "100vh"}} {...foldSection} ><foldSection.component/></Section>
        {sections.slice(1).map((section,i) => <Section key={section.to} topSpacer={navHeight} {...section} ><section.component/></Section>)}
      </div>
      <Footer />
      <article style={{background:"black",color:'#fefefe', textAlign:"center", padding:"1em 0"}}>Made with ❤ by Florine & Jérôme ;)</article>
      <article style={{background:"black",color:'#fefefe', textAlign:"center", padding:"1em 0"}}>Merci à <a href="https://www.instagram.com/lespetitscoffrets">Julie les petits coffrets</a> pour les faire-part et le logo !</article>
    </>
  );
}

export default App;
