import { textSection } from './../components/section/articlecss'

function Word() {

  return <article style={textSection}>
   <p> Merci d'avoir contribué à rendre ce jour inoubliable par votre présence, vos sourires et vos attentions. </p>
   <p> Nous espérons que ces images vous ont transportée autant que nous !</p>
   <p> N'hésitez pas à venir nous rendre visite, la porte est ouverte... </p>
   </article>
}

export default Word;
