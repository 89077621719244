//import Rsvp from './scenes/rsvp'
//import FoldBefore from './scenes/fold_before'
import FoldAfter from './scenes/fold_after'
// import Prog from './scenes/prog'
// import Venues from './scenes/venues'
// import Covid from './scenes/covid'
// import Rooms from './scenes/rooms'
// import Children from './scenes/children'
import PhotosVideos from './scenes/photosvideos/photosvideos'
//import Word from './scenes/word'
import Word from './scenes/word_after';
//import foldPic from './pics/foldBefore.JPG'
import foldPic from './pics/foldAfter.jpg'

export const mainSections = [
  { to: 'fold', name: "Accueil", component: FoldAfter, backgroundImg: foldPic }, //le truc vraiment chouette aurait été de mettre un if date > 18 Juin, fold after... haha
  // { to: 'word', name: "Word", component: Word },
  // { to: 'venu', name: "Lieux & Parking", title: "Lieux & Parking", component: Venues },
  // { to: 'prog', name: "Programme", title: "Programme", component: Prog },
  // { to: 'rooms', name: "Chambres", title: "Chambres", component: Rooms, backgroundColor: "#f0f0f0" },
  // { to: 'children', name: "Enfants", title: "Enfants", component: Children },
  //{ to: 'photos', name: "Photos", title: "Photos", component: Photos, backgroundColor: "#f0f0f0" }, --> devenu rsvp section
  //{ to: 'videos', name: "Videos", title: "Videos", component: Videos, backgroundColor: "#f0f0f0" }, --> dans photo (aka rsvp section)

  //{ to: 'covid', name: "Mesures Sanitaires", title: "Mesures Sanitaires", component: Covid },

];
//export const rsvpSection = { to: 'rsvp', name: <em>Je confirme ma présence</em>, title: <em>Je confirme ma présence</em>, component: Rsvp, backgroundColor: "#f0f0f0" }
//maintenant rsvpSection devient les photos
export const rsvpSection = { to: 'photosvideos', name: <em>Je veux voir les photos !</em>, title: "Les photos sont arrivées !", component: PhotosVideos, backgroundColor: "#f0f0f0" }
export const wordSection = { to: '', name: '', title: "Merci !", component: Word }

export const sections = [...mainSections, rsvpSection, wordSection]